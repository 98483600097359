@font-face {
  font-family: 'GucciSans';
  src: url('../src/Gucci-Font/Helvetica-Light.woff2') format('woff2'); /* Adjust path */
  src: url('../src/Gucci-Font/Helvetica-Bold.woff2') format('woff2'); /* Adjust path */
  src: url('../src/Gucci-Font/Helvetica-Book.woff2') format('woff2'); /* Adjust path */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'GucciSans', sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
