@font-face {
  font-family: 'GucciSans';
  src: url('../src/Gucci-Font/Helvetica-Light.woff2') format('woff2');
  /* Adjust path */
  src: url('../src/Gucci-Font/Helvetica-Bold.woff2') format('woff2');
  /* Adjust path */
  src: url('../src/Gucci-Font/Helvetica-Book.woff2') format('woff2');
  /* Adjust path */
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'GucciSans', sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

.spin-container {
  perspective: 1000px;
  width: 200px;
  height: 200px;
  margin: 50px auto;
}

.spinning-image {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: spin 5s linear infinite;
}

.spinning-image img {
  width: 100%;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotateX(0deg) rotateY(0deg);
  }

  to {
    transform: rotateX(360deg) rotateY(360deg);
  }
}


.App {
  text-align: center;
  /* background-image: url("./images/darkmode-bg-darker.png"); */
  height: 100vh;
  background-repeat: no-repeat;
  overflow-x: hidden;
 
  &.light-mode {
    background-color: #ffffff;
    color: #000000;
  }

  /* Dark mode styles */
  &.dark-mode {
    background-color: #333333;
    color: #ffffff;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #12121294; 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hsl(0deg 0% 20% / 84%); 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* CUSTOMIZE BUTTON UI START HERE */

.btn-error {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsl(0deg 100% 73.83%);
  --btn-bg-2: #f00;
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 9.5px;
  min-width: 120px;
  /* font-family: "Segoe UI", system-ui, sans-serif; */
  font-family: 'GucciSans', sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  border: none;
  border-radius: var(--radii);
  color: #ffffff;
}

.btn-navigate {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1);
  --btn-bg-2: hsla(210 99% 48% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 9.5px;
  min-width: 120px;
  /* min-height: 10px; */
  font-family: "Segoe UI", system-ui, sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  /* background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%); */
  /* background-image: linear-gradient(#d75059, #f3d0c7, #d75059); */
  background-image: linear-gradient(#2bc5dc, #0e2a4f, #1d3c70);
  border: none;
  color: #ffffff;
  box-shadow: 0 0 10px #eeeeee;
  border-radius: 15px;
  box-shadow: 0 0 10px #017af4;
  /* box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35); */
}

.btn-navigate:hover {
  background-position: right center;
  transform: translateY(-2px);
}

.btn-navigate span {
  position: relative;
  z-index: 1;
  text-shadow: rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px;
}

.btn-navigate:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
  /* background: linear-gradient(90deg, #f40339, #370974, #9506fa, #03a9f4); */
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  box-shadow: 0 0 50px #eeeeee;
}

.btn-navigate:hover:before {
  filter: blur(20px);
  opacity: 1;
}

.btn-donate {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1);
  --btn-bg-2: hsla(210 99% 48% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 9.5px;
  min-width: 120px;
  /* min-height: 10px; */
  font-family: "Segoe UI", system-ui, sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  border: none;
  border-radius: var(--radii);
  color: #ffffff;
  /* box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35); */
}

  /* background-image: linear-gradient(180deg, #e73059, #ac193a 50%, #e73059); */
  /* background-image: linear-gradient(180deg, #e73059, #fff 50%, #e73059); */
  /* background-image: linear-gradient(90deg, #f40339e6, #bd0a32c9, #9506fa, #03a9f4); */
  /* background-image: linear-gradient(180deg, #f40339e6, #bd0a32c9, #9506fa 10%, #03a9f4 10%,  #f40339e6, #bd0a32c9); */
  /* background-image: linear-gradient(180deg, #f40339e6, #bd0a32c9 37%, #f40339e6,  #fff 48%, #f40339e6, #bd0a32c9, #f40339e6); */
  /* text-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */

.btn-enter-grad {
  /* background-image: linear-gradient(180deg,#f40339e6 10%,#bd0a32c9 37%,#f40339e6,rgb(0 0 0 / 0%) 48%, #f40339e6, #e73059, #f40339e6); */
   background-image: linear-gradient(#d75059, #f3d0c7, #d75059);
   /* background-image: radial-gradient(#d75059, #f3d0c7, #be3d45); */
   /* background-color: #ff0000;
  background-image: linear-gradient(180deg, #ff0000 0%, #ff6d6d 25%, #ffffff 47%, #ffffff 53%, #ff5d5d 80%, #ff0000 100%); */
  /* background-image: linear-gradient(to right, rgba(255,0,0,0), #d75059); */
  text-align: center;
  text-transform: uppercase;
  transition: background-position 0.5s, transform 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 10px #eeeeee;
  border-radius: 8px;
  display: block;
  display: flex;
  font-weight: bold;
  letter-spacing: 4px;
  border: none; 
  cursor: pointer;
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.btn-enter-grad:hover {
  background-position: right center;
  transform: translateY(-2px);
}

.btn-enter-grad span {
  position: relative;
  z-index: 1;
  text-shadow: rgba(21, 2, 97, 0) 2px -2px 5px, #990425 -2px -2px 5px, #990425 -2px 2px 5px, #990425 2px 2px 5px;
}

.btn-enter-grad:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #f40339, #370974, #9506fa, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  box-shadow: 0 0 50px #eeeeee;
}

.btn-enter-grad:hover:before {
  filter: blur(20px);
  opacity: 1;
}
 
/* plain gradient */
/* .btn-enter-grad:hover {
  background-position: right center; 
  color: #fff;
  text-decoration: none;
} */

.upload-btn-disabled {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1);
  --btn-bg-2: hsla(210 99% 48% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5emdefaultpointer;
  padding: 9.5px;
  min-width: 120px;
  font-family: "Segoe UI", system-ui, sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  /* background-image: linear-gradient(325deg, rgba(0, 0, 0, 0.12) 0%, rgb(0 0 0 / 12%) 55%, rgb(0 0 0 / 12%) 90%); */
  background-image: linear-gradient(325deg, rgb(0 0 0 / 51%) 0%, rgb(0 0 0 / 12%) 55%, rgb(0 0 0 / 46%) 90%);
  border: none;
  border-radius: 6px;
  color: #ffffff;
}

.btn-cancel {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: rgb(204, 235, 248);
  --btn-bg-2: rgb(254, 254, 254);
  --btn-bg-color: rgb(248, 250, 252);
  --radii: 0.5em;
  cursor: pointer;
  padding: 8px;
  min-width: 120px;
  /* min-height: 10px; */
  font-family: "Segoe UI", system-ui, sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  border: 1px solid #0178f2;
  border-radius: var(--radii);
  color: #0178f2;
  /* box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35); */
}

.blink {
  animation: blinker 1.5s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.dialog-bg {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1);
  --btn-bg-2: hsla(210 99% 48% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  padding: 9.5px;
  min-width: 120px;
  /* min-height: 10px; */
  font-family: "Segoe UI", system-ui, sans-serif;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  border: none;
  color: #ffffff;
}

.btn-donate:hover {
  background-position: right top;
}

.btn-donate:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 2px var(--btn-bg-color), 0 0 0 1px var(--btn-bg-2);
}

.dialog-bg:hover {
  background-position: right top;
}

.dialog-bg:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 2px var(--btn-bg-color), 0 0 0 1px var(--btn-bg-2);
}

.btn-cancel:hover {
  background-position: right top;
}

.btn-cancel:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 2px var(--btn-bg-color), 0 0 0 1px var(--btn-bg-2);
}

@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}

/* CUSTOMIZE BUTTON UI END HERE */
@keyframes pulse {
  0% {
    border-width: 2px;
    border-color: #F00;
  }

  50% {
    border-width: 8px;
    border-color: #e74c3c;
  }

  100% {
    border-width: 2px;
    border-color: #F00;
  }
}

img[usemap],
map area[shape] {
  outline-color: #F00;
}

.pulseIcon {
  border: 2px solid red;
  border-radius: 8px;
  animation: pulse 2s infinite;
}



/* CustomScrollbar.css */
.ViewGemDetailsContainer {

  /* Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 5px;
    /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9ea39e;
    /* Set the color of the scrollbar thumb */
    border-radius: 8px;
    /* Set the border radius of the thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the color of the scrollbar track */
    border-radius: 8px;
    /* Set the border radius of the track */
  }

  /* Firefox */
  /* scrollbar-width: thin;
  scrollbar-color: #9ea39e; */



}

/* Series Cover Art */
.dim-overlay {
  position: relative;
  display: inline-block;
}

.dim-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Adjust the last value (alpha) to control the level of dimness */
  pointer-events: none;
  /* Allows click-through to the image below */
}

.dim-image {
  display: block;
  max-width: 100%;
  height: auto;
}


/* homev2 */

.container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 540px; */
  height: 500px;
  overflow: hidden;
}

  .containerAndroidMobileView {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: scale(0.9);
    height: 44vh;
    /* height: 80vh;
    zoom: 50%; */
    /* zoom: 30%;
    height: 80vh; */
  } 

  .containerIOSMobileView {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  /* For scaling */
  @media screen and (-webkit-min-device-pixel-ratio:0) { /* Targets Webkit browsers (including Chrome and Safari) */
    .containerIOSMobileView {
      transform: scale(0.9);
      height: 40vh;
      /* height: 47vh; */
      /* zoom: 30%;
      height: 40vh; */
    }
  }


.scope {
  position: relative;
  width: 290px;
  height: 120px;
  transform-style: preserve-3d;
  animation: slid 140s linear infinite;
  cursor: grab;
}

@keyframes slid {
  0% {
    transform: perspective(1000px) rotateY(var(--start-angle, 0deg));
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.scope:hover {
  animation-play-state: paused;
  cursor: grab;
}

.scope span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 36deg)) translateZ(550px);
}

.scope span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  cursor: pointer;
  filter: contrast(110%);
}


.scope span:hover img {
  transform: translateY(-15px) scale(1.3);
  cursor: grab;
  filter: contrast(120%);
}

.scope span h3 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  text-align: center;
  /* color: rgb(0 18 115); */
  color: #000000;
  font-family: 'GucciSans', sans-serif;
  font-size: 8px;
  font-weight: 400;
}

.focusedScopeOverlay {
  position: 'absolute';
  top: 0;
  left: 0;
  height: '100%';
  width: '100%';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  position: relative;
  background: 'rgba(0, 0, 0, 0.5)';
}

.focusedScopeOverlay:hover {
  animation-play-state: paused;
}

.focusedScopeOverlay span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 36deg)) translateZ(550px);
}

.focusedScopeOverlay span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  cursor: pointer;
  filter: contrast(110%);
}

.focusedScopeOverlay span:hover img {
  transform: translateY(-15px) scale(1.3);
  cursor: grab;
  filter: contrast(120%);
}

.focusedScopeOverlay span h3 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  text-align: center;
  color: #000000;
  font-family: 'GucciSans', sans-serif;
  font-size: 8px;
  font-weight: 400;
}

.focusedScope {
  position: relative;
  width: 290px;
  height: 120px;
  transform-style: preserve-3d;
  animation-play-state: paused;
  cursor: grab;
  transform: translateY(-15px) scale(9.3);
}

.focusedScope:hover {
  animation-play-state: paused;
}

.focusedScope span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 36deg)) translateZ(550px);
}

.focusedScope span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  cursor: pointer;
  filter: contrast(110%);
}

.focusedScope span:hover img {
  transform: translateY(-15px) scale(1.3);
  cursor: grab;
  filter: contrast(120%);
}

.focusedScope span h3 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  text-align: center;
  color: #000000;
  font-family: 'GucciSans', sans-serif;
  font-size: 8px;
  font-weight: 400;
}

.focusedImage {
  display: block;
  margin: 0 auto;
}

.focusedImage img {
  /* border: 0.1px solid #2f2c91; */
  box-shadow: 0 0 4px rgba(47, 44, 145, 0.5);
  transform: translateY(-15px) scale(1.3);
}


.slid-animation {
  animation: slid 140s linear infinite;
  /* animation-play-state: paused; */
}

.scopeB {
  position: relative;
  width: 290px;
  height: 120px;
  transform-style: preserve-3d;
  /* animation: slid 140s linear infinite; */
  cursor: grab;

}


.scopeB span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 36deg)) translateZ(550px);
}

.scopeB span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  transition: 2s;
  cursor: pointer;
  filter: contrast(110%);
}


.header-title p {
  font-size: 27px;
  text-align: center;
  margin: auto;
}

.series-menu {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #4d4949;
  font-family: 'GucciSans', sans-serif;
}

.cg-social-media {
  text-align: center;
  margin-top: 20px;
}



.metamask-avatar {
  border: 4px solid #000;
}

.test-container {
  display: flex;
  padding: 0 35px;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  background: #343F4F;
}

.wrapper {
  display: flex;
  max-width: 1200px;
  position: relative;
}

.wrapper i {
  top: 50%;
  height: 44px;
  width: 44px;
  color: #343F4F;
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  text-align: center;
  line-height: 44px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.9);
}

.wrapper i:hover {
  background: #f2f2f2;
}

.wrapper i:first-child {
  left: -22px;
  display: none;
}

.wrapper i:last-child {
  right: -22px;
}

.wrapper .carousel {
  font-size: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.carousel.dragging {
  cursor: grab;
  scroll-behavior: auto;
}

.carousel.dragging img {
  pointer-events: none;
}

.carousel img {
  height: 340px;
  object-fit: cover;
  user-select: none;
  margin-left: 14px;
  width: calc(100% / 3);
}

.carousel img:first-child {
  margin-left: 0px;
}

@media screen and (max-width: 900px) {
  .carousel img {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 550px) {
  .carousel img {
    width: 100%;
  }
}


/* responsive page */
.container-gem {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: .75rem;
  padding-left: .75rem
}

.container-toolbar {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: .75rem;
  padding-left: .75rem
}


@media (min-width: 1084px) {
  .container-gem {
    width: 95%;
    box-sizing: content-box
  }

  .container-toolbar {
    padding-right: .75rem;
    padding-left: .75rem;
    width: 100%;
    box-sizing: content-box
  }

}

@media (min-width: 1400px) {
  .container-gem {
    max-width: 1600px
  }

  .container-toolbar {
    max-width: 1600px
  }

}

.image-carousel-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  perspective: 1000px;
}

.carousel {
  display: flex;
  transform-style: preserve-3d;
  animation: slid 140s linear infinite;
}

.carousel-item {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active {
  z-index: 1;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.pdf-container {
  width: auto;
  height: auto;
  box-shadow: rgb(0 0 0 / 22%) 0px 4px 20px;
  margin: auto;
}

@media (max-width: 597px) {
  .pdf-container {
    width: auto;
    /* Adjust as needed */
    height: auto;
    /* Adjust as needed */
    margin: auto;
    zoom: 65%
  }
}

.containerHome {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: .75rem;
  padding-left: .75rem;
  box-sizing: border-box;
}

@media (min-width: 1084px) {
  .containerHome {
    padding-right: 5%;
    padding-left: 5%;
    width: 95%;
    box-sizing: content-box
  }
}

@media (min-width: 1400px) {
  .containerHome {
    width: 95%;
    min-width: 1400px;
    max-width: 1600px;
    margin: 0 auto;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.containerHomeAllSeries {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-right: .75rem;
  padding-left: .75rem;
  box-sizing: border-box;
}

@media (min-width: 1084px) {
  .containerHomeAllSeries {
    padding-right: 5%;
    padding-left: 5%;
    width: 80%;
    box-sizing: content-box
  }
}

@media (min-width: 1400px) {
  .containerHomeAllSeries {
    width: 80%;
    min-width: 1400px;
    max-width: 1600px;
    margin: 0 auto;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* @media (max-width: 597px) {
  .container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: hidden;
    zoom: 50%
  }

} */

.footer-class {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 9;
}

.homeText {
  width: 29%;
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (min-width: 688px) and (max-width: 688px) and (min-height: 1031px) and (max-height: 1031px) {
  .homeText {
    width: 70%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .homeText {
    width: 63%;
  }
}

@media only screen and (width: 1024px) and (height: 1366px) {
  .homeText {
    width: 50%;
  }
  
}

@media (min-width: 320px) and (max-width: 376px) {
  .homeText {
    width: 99%;
  }
}

@media (min-width: 378px) and (max-width: 420px) {
  .homeText {
    width: 95%;
  }
}


@media (min-width: 450px) and (max-width: 600px) {
  .homeText {
    width: 89%;
  }
}


@media only screen and (width: 1280px) and (height: 800px) {

  .homeText {
    width: 40%;
  }

  .footer-class {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    z-index: 1000;
  }

  .landing_footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    z-index: 1000;
  }
}


@media only screen and (width: 1440px) and (height: 900px) {

  .homeText {
    width: 34%;
  }

}


@media only screen and (width: 1280px) and (height: 950px) {

  .homeText {
    width: 40%;
  }
  
}

.zoom-125 .homeText {
  width: 33%;
}

.zoom-125 .footer-class {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 1000;
}


.zoom-150 .homeText {
  width: 40%;
}

.zoom-150 .footer-class {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 1000;
}


.series-name {
  /* position: absolute; */
  bottom: 80px;
  left: 0;
  text-transform: uppercase;
  font-size: 32;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 1000;
}

.series-name-body {
  position: absolute;
  bottom: 50px;
  left: 0;
  text-transform: uppercase;
  font-size: 24;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 1000;
}

.series-zoom-125 .series-name {
  position: relative;
  bottom: 0px;
}

.series-zoom-150 .series-name {
  position: relative;
  bottom: 0px;
}


/* Shooting star */
/* .star {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 2px;
  background: linear-gradient(-45deg, #FFF, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #FFF);
  animation: tail 3s ease-in-out infinite, falling 3s ease-in-out infinite;
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 200px;
  }
  100% {
    width: 0;
  }
}

@keyframes falling {
  0% {
    transform: translate(-50%, -50%) rotateZ(45deg) translateY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(45deg) translateY(200px);
  }
}

.star::before,
.star::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #FFF, rgba(0, 0, 255, 0));
  border-radius: 100%;
  transform: translate(-50%, -50%) rotateZ(45deg);
  animation: shining 3s ease-in-out infinite;
}

@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}

.star::after {
  transform: translate(-50%, -50%) rotateZ(-45deg);
}

.star:nth-child(1) {
  top: calc(50% - 100px);
  left: calc(50% - 250px);
  animation-delay: 1s;
}

.star:nth-child(2) {
  top: calc(50% - 50px);
  left: calc(50% - 200px);
  animation-delay: 1.2s;
}

.star:nth-child(3) {
  top: calc(50% - 0px);
  left: calc(50% - 150px);
  animation-delay: 1.4s;
}

.star:nth-child(4) {
  top: calc(50% - -50px);
  left: calc(50% - 100px);
  animation-delay: 1.6s;
}

.star:nth-child(5) {
  top: calc(50% - -100px);
  left: calc(50% - 50px);
  animation-delay: 1.8s;
} */
@keyframes animateBg{
  0%,100%{
      transform: scale(1);
  }
  50%{
      transform: scale(1.2);
  }
}

.star{
  position: absolute;
  top:50%;
  left:50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255,255,255,0.1),0 0 0 8px rgba(255,255,255,0.1),0 0 20px rgba(255,255,255,0.1);
  animation: animate 10s linear infinite;
}

.star::before{
  content:'';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100px; /* Adjusted width for shorter tail */
  height: 1px;
  background: linear-gradient(90deg,#fff,transparent);
}
@keyframes animate
{
  0%
  {
      transform: rotate(315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;
  }
  100%
  {
      transform: rotate(315deg) translateX(-1000px);
      opacity: 0;
  }
}
.star:nth-child(1){
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0s;
  animation-duration: 1.5s;
}
.star:nth-child(2){
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}
.star:nth-child(3){
  top: 80;
  right: 0px;
  left: initial;
  animation-delay: 0.4s;
  animation-duration: 2s;
}
.star:nth-child(4){
  top: 10;
  right: 150px;
  left: initial;
  animation-delay: 0.6s;
  animation-duration: 1.5s;
}
.star:nth-child(5){
  top: 0;
  right: 400px;
  left: initial;
  animation-delay: 0.8s;
  animation-duration: 2.5s;
}
.star:nth-child(6){
  top: 0;
  right: 600px;
  left: initial;
  animation-delay: 1s;
  animation-duration: 3s;
}
.star:nth-child(7){
  top: 300px;
  right: 0px;
  left: initial;
  animation-delay: 1.2s;
  animation-duration: 1.75s;
}
.star:nth-child(8){
  top: 0px;
  right: 700px;
  left: initial;
  animation-delay: 1.4s;
  animation-duration: 1.55s;
}
.star:nth-child(9){
  top: 0px;
  right: 1000px;
  left: initial;
  animation-delay: 0.75s;
  animation-duration: 2.25s;
}
.star:nth-child(9){
  top: 0px;
  right: 450px;
  left: initial;
  animation-delay: 2.75s;
  animation-duration: 2.75s;
}

/* link  */
.content__item {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* counter-increment: itemcounter; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* position: relative; */
  text-align: justify;
  text-transform: none;
}

.content__item::before {
  color: var(--color-number);
  position: absolute;
  top: 0;
  left: 0;
  /* content: counters(itemcounter, ".", decimal-leading-zero); */
}



.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
  /* show by default */
}


/* .link:hover {
  text-transform: uppercase; 
  color: #0178f2; 
} */

.link--thebe {
  font-family: angie-sans, sans-serif;
}

.link--thebe::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
}

.link--thebe:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 2, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
}

.link--thebe::after {
  content: '';
  top: calc(100% + 4px);
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s 0.1s cubic-bezier(0.2, 1, 0.8, 1);
}

.link--thebe:hover::after {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
}

.link--thebe:hover::before,
.link--thebe:hover::after {
  background-color: #0178f2; /* Change line color on hover to #0178f2 */
}


.btn,
p {
  display: inline-block;
  vertical-align: middle;
}

.btn {
  position: relative;
  width: 200px;
  height: 40px;
  margin: 8px;
  cursor: pointer;
}

.btn p {
  width: 100%;
  height: 100%;
}

.btn a {
  display: block;
  width: 100%;
  height: 40px;
  margin-top: -10px;
}

.btn span {
  display: block;
  width: 100%;
  height: 40px;
  padding-top: 0px;
}



.btn p::before {
  content: "";
  position: absolute;
  border-top: 1px solid #0178f2;
  border-bottom: 1px solid #0178f2;
  top: -1px;
  left: -6px;
  right: -6px;
  bottom: -1px;
}

.btn p::after {
  content: "";
  position: absolute;
  border-left: 1px solid #0178f2;
  border-right: 1px solid #0178f2;
  top: -6.5px;
  bottom: -6.5px;
  left:-1px;
  right: -1px;
}

.btn:hover p::before,
.btn:hover p::after {
  border: none;
}



.btn a {
  background: inherit;
  color: inherit;
  transition-property: background, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
a {
  text-decoration: none;
  color: inherit;
}



.btn:hover {
  /* background: white; */
  /* background: rgba(58, 125, 233, 0.25); */
  background: #0178f2;
  border: 1px solid #0178f2;
  color: #fff;
}



.btn a::before {
  content: "";
  position: absolute;
  border: 1px solid #fff;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  transition-property: opacity, background, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  opacity: 0;
}

.btn:hover a::before {
  opacity: 1;
}

.btn::before {
  content: "";
  height: 1px;
  position: absolute;
  background: #0178f2;
  bottom: 46px;
  left: 0;
  right: 0;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.btn::after {
  content: "";
  height: 1px;
  position: absolute;
  background: #0178f2;
  top: 46px;
  left: 0;
  right: 0;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.btn:hover::before,
.btn:hover::after {
  left: 50%;
  right: 50%;
}

.btn span::before {
  content: "";
  width: 1px;
  position: absolute;
  background: #0178f2;
  top: 0;
  bottom: 0;
  left: -7px;
  right: 0;
  transition-property: top, bottom;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.btn span::after {
  content: "";
  width: 1px;
  position: absolute;
  background: #0178f2;
  top: 0;
  bottom: 0;
  right: -7px;
  transition-property: top, bottom;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  
}

.btn:hover span::before,
.btn:hover span::after {
  top: 50%;
  bottom: 50%;
}

.subscribe-btn {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1);
  --btn-bg-2: hsla(210 99% 48% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 8px;
  min-width: 50px;
  /* min-height: 10px; */
  /* font-family: "Segoe UI", system-ui, sans-serif; */
  transition: 0.8s;
  background-size: 280% auto;
  /* background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%); */
  /* background-image: linear-gradient(#d75059, #f3d0c7, #d75059); */
  background-image: linear-gradient(#2bc5dc, #0e2a4f, #1d3c70);
  font-size: small;
  border: none;
  color: #ffffff;
  box-shadow: 0 0 10px #eeeeee;
  border-radius: 15px;
  box-shadow: 0 0 10px #017af4;
  font-family: 'GucciSans', sans-serif;
  /* height: 25px; */
}

/* input {
  padding: .8rem 1rem;
  width: 10%;
  border-radius: 5rem;
  outline: none;
  border: .1rem solid #017af4;
  font-size: 14px;
  box-shadow: 0 0 10px #017af4;
  height: 40px;
} */

::placeholder {
  color: #5f5f5fea;
  font-size: 12px;
  font-family: 'GucciSans', sans-serif;
}

div a p {
  margin: 0;
  height: 54px;
  line-height: 54px;
  box-sizing: border-box;
  z-index: 1;
  left: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}
div a p span.base {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  border: 1px solid #0178f2;
}
div a p span.base:before {
  content: "";
  width: 2px;
  height: 2px;
  left: -1px;
  top: -1px;
  background: #0f1923;
  position: absolute;
  transition: 0.3s ease-out all;
}
div a p span.bg {
  left: -5%;
  position: absolute;
  background: #0178f2;
  width: 0;
  height: 100%;
  z-index: 3;
  transition: 0.3s ease-out all;
  transform: skewX(-10deg);
}
div a p span.text {
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
div a p span.text:after {
  content: "";
  width: 4px;
  height: 4px;
  right: 0;
  bottom: 0;
  background: #0f1923;
  position: absolute;
  transition: 0.3s ease-out all;
  z-index: 5;
}
div a:hover {
  color: #ece8e1;
}
div a:hover span.bg {
  width: 110%;
}
div a:hover span.text:after {
  background: #ece8e1;
} 


.container-coming {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: .75rem;
  padding-left: .75rem
}

@media (min-width: 1084px) {
  .container-coming {
      padding-right: 5%;
      padding-left: 5%;
      width: 90%;
      box-sizing: content-box
  }

}

@media (min-width: 1400px) {
  .container-coming {
      max-width: 1600px
  }


}
